import * as _ from 'lodash';
import moment from 'moment';

export const startsWith = (sourceStr, search_string) => {
  if (!search_string) {
    return true;
  }

  return (sourceStr || '').toLowerCase().indexOf(search_string.toLowerCase()) >= 0;
};

export const millisecondsToHourMinute = (value) => {
  const d = moment.duration(value, 'milliseconds');
  const hours = Math.floor(d.asHours());
  const mins = Math.floor(d.asMinutes()) - hours * 60;
  return hours + ':' + mins;
};

export const roundOfDigit = (value, digit) => {
  return _.round(value, digit);
};

export const convertTime = (timeStr) => {

  const validTimeStr = timeStr || '0:0';
  
  const [minutesStr, secondsStr] = validTimeStr.split(':');
  const totalMinutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}:${minutes}:${seconds}`;
};

export const convertSeconds = (totalSeconds) => {
  const seconds = Math.floor(totalSeconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${minutes}:${remainingSeconds}`;
};

export const convertMinutes = (totalMinutes) => {
  const totalSeconds = Math.floor(totalMinutes * 60);
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
};
export const commaSeperate = (value) => {
  const formatted = _.toNumber(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatted;
};

export const convertHoursInHMS = (value) => {
  const durationInHours = value;

  // Extract hours, minutes, and seconds
  const hours = Math.floor(durationInHours);
  const minutes = Math.floor((durationInHours - hours) * 60);
  const seconds = Math.floor(((durationInHours - hours) * 60 - minutes) * 60);


  const formattedDuration = hours > 0 ? `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s` : `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`
  return formattedDuration

}
