import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
// import { BsDot } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import DropDown from 'components/inputs/DropDown';
import { FaChargingStation, FaMapMarkerAlt } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
// import ReactStars from 'react-rating-stars-component';
import Button from 'components/inputs/Button';
// import General from './General';
import Chargers from './Chargers';
import Consumption from './Consumption';
// import Reservations from './Reservations';
// import Reviews from './Reviews';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_STATION_STATUS, GET_CHARGING_STATION, GET_STATION_INFO } from 'actions/chargingStation';
import { DELETE_CHARGING_STATION } from 'actions/chargingStation';
import Switch from 'components/inputs/Switch';
import * as _ from 'lodash';
import DeleteModal from 'components/general/DeleteModal';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { FiEdit2 } from 'react-icons/fi';
import Footer from 'components/general/Footer';
import { SUPPORT } from 'components/common/constant';
import moment from 'moment';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import ReactDOMServer from 'react-dom/server';
import { commaSeperate, convertHoursInHMS } from 'components/common/utils';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const StationDetails = () => {
  const { stationId } = useParams();
  const [currentTab, setCurrentTab] = useState('chargers');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  // const [checked, setChecked] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.userProfile);
  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  const stationInfo = useSelector((state) => state?.chargingStation?.stationInfo);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  // const isLoader = useSelector((state) => state.chargingStation.isLoading);
  // const avgRating = _.round(_.get(chargingStationData, 'avg_rating', 0), 1);
  const currentUserRole = _.get(profileData, 'role', '');
  const [activeMarker, setActiveMarker] = useState(false);
  const [mapRef, setMapRef] = useState(null);

  const [center, setCenter] = useState({ lat: 24.4539, lng: 54.3773 });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  });
  const loadHandler = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    loadHandler();
  }, [mapRef, center, chargingStationData]);

  const handleActiveMarker = (marker) => {
    setActiveMarker(marker);
  };

  const handleCloseDeleteModel = () => setShowDeleteModal(false);
  const handleCloseInfoModal = () => setShowInfoModal(false);

  const deleteChargingStation = useCallback((id) => dispatch({ type: DELETE_CHARGING_STATION, payload: id }), []);

  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION, payload: id });
  }, []);

  const getStationInfo = useCallback((id) => {
    dispatch({ type: GET_STATION_INFO, payload: id });
  }, []);

  useEffect(() => {
    getStationDetail(stationId);
    getStationInfo(stationId);
  }, [stationId]);

  const editStationDetail = useCallback(() => {
    const stationData = {
      id: chargingStationData.id,
      is_enabled: chargingStationData.is_enabled !== true,
    };
    dispatch({ type: CHANGE_STATION_STATUS, payload: stationData });
  }, [chargingStationData]);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const energySold = {
    labels: _.map(stationInfo?.energySoleChart, (item) => item?.hour),
    datasets: [
      {
        label: 'Energy Sold in kWh',
        data: _.map(stationInfo?.energySoleChart, (item) => item?.unit),
        backgroundColor: '#FED035',
        borderColor: '#FED035',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hours', // Label for x-axis
        },
      },
      y: {
        title: {
          display: true,
          text: 'Units (in kWh)', // Label for y-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          color: '#ffffff',
        },
      },
    },
  };

  const getDataUrlFromIcon = (color, size) => {
    const svgMarkup = ReactDOMServer.renderToStaticMarkup(<FaMapMarkerAlt color={color} size={size} />);

    const svgBlob = new Blob([svgMarkup], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);

    return url;
  };

  const getMarkerIcon = (status, accessType) => {
    let color;

    if (accessType === 'Private') {
      color = '#cba31b';
    } else {
      switch (status) {
        case 'Available':
          color = '#75ed2f';
          break;
        case 'Unavailable':
        case 'Under Maintenance':
          color = '#ff8b00';
          break;
        case 'Coming Soon':
          color = '#8b91a1';
          break;
        case 'PowerLoss':
          color = '#be210b';
          break;
        case 'In Use':
          color = '#4a75f2';
          break;
        default:
          color = '#8b91a1';
          break;
      }
    }

    const iconUrl = getDataUrlFromIcon(color, 40);

    return {
      url: iconUrl,
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 20),
    };
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Stations and Chargers" backTo={location.state ? `/${location.state?.backTo}` : '/stations'} />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="station-details__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="station-details__main">
              <Card>
                <div className="station-info--box">
                  <div className="station-info-content">
                    <div className="station-info__inner">
                      <div className="station-icon--block">
                        <div className="station-icon">
                          <FaChargingStation />
                        </div>
                      </div>
                      <div className="station-info--wrapper">
                        <div className="station-status--block">
                          <span className="info-name">{_.get(chargingStationData, 'name', '')}</span>
                          <div className="station-block-charger">
                            <div
                              className={'station-status-block--inner'}
                              title="Station Status"
                              style={{
                                background:
                                  chargingStationData.status === 'Unavailable'
                                    ? '#FF8B00'
                                    : chargingStationData.status === 'Coming Soon'
                                    ? '#8B91A1'
                                    : chargingStationData.status === 'In Use'
                                    ? '#4975F2'
                                    : chargingStationData.status === 'Under Maintenance'
                                    ? '#BD210B'
                                    : chargingStationData.status === 'PowerLoss'
                                    ? '#BD210B'
                                    : '#0f9b72',
                                borderRadius: '20px',
                                padding: '5px 20px',
                                color: chargingStationData.status === 'Available' ? 'White' : 'White',
                                fontWeight: chargingStationData.status === 'Available' ? '600' : 'none',
                              }}
                            >
                              {chargingStationData.is_enabled === false && (
                                <div
                                  className={`station-status ${
                                    _.get(chargingStationData, 'is_enabled', '') === true ? 'status-color-green text--size' : 'status-color-red'
                                  }`}
                                >
                                  {/* <BsDot className="dot--icon" size={20} /> */}
                                  <span className="status-text">{'Disabled'}</span>
                                </div>
                              )}
                              <div
                                className={`station-status ${
                                  _.get(chargingStationData, 'status', '') === 'Available' ? 'status-color-white' : 'status-color-white'
                                }`}
                              >
                                <span>{_.get(chargingStationData, 'status', '')}</span>
                              </div>
                            </div>
                            <span className="station-total-charger" title="Number of Chargers">
                              {_.get(chargingStationData, 'chargers', []).filter((charger) => !charger.is_deleted).length}
                            </span>
                          </div>
                        </div>
                        {/* <div className="station-info-rating--block">
                        <div className="rating-star-block">
                          {!_.isEmpty(chargingStationData) && (
                            <ReactStars
                              edit={false}
                              a11y={true}
                              count={5}
                              value={avgRating}
                              size={14}
                              isHalf={true}
                              emptyIcon={<BsStar />}
                              halfIcon={<BsStarHalf />}
                              filledIcon={<BsStarFill />}
                              activeColor="#BE210B"
                              color="#BE210B"
                            />
                          )}
                        </div>
                        <span className="rating-count">
                          {avgRating}/5 ({_.get(chargingStationData, 'rating_count', '')})
                        </span>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  {currentUserRole !== SUPPORT && (
                    <>
                      <div className="station-dropdown_btn">
                        <div className="tab-nav-block__right">
                          <Button onClick={() => history.push(`/addCharger?station=${stationId}`)}>Add Charger</Button>
                        </div>
                        <div
                          title="View More Information"
                          className="station-info"
                          onClick={() => {
                            setShowInfoModal(true);
                          }}
                        >
                          <AiOutlineInfoCircle />
                        </div>
                        <DropDown
                          trigger={
                            <div className="dropdown-icon">
                              <BsThreeDotsVertical />
                            </div>
                          }
                          popupClass="bottom-left"
                        >
                          <ul>
                            <li className="item">
                              <div onClick={editStationDetail} className="station--switch">
                                <Switch
                                  label={chargingStationData.is_enabled === true ? 'Disable Station' : 'Enable Station'}
                                  // onChange={() => setChecked(!checked)}
                                  checked={chargingStationData.is_enabled === false}
                                  // onClick={editStationDetail}
                                />
                              </div>
                            </li>
                            <li className="item" onClick={() => setShowDeleteModal(true)}>
                              <div className="delete--station">
                                <RiDeleteBin6Line color={'#E12F17'} />
                                <span className="delete-station-text">Delete Station</span>
                              </div>
                            </li>
                            <li className="item" onClick={() => history.push(`/editStation/${chargingStationData.id}`)}>
                              <div className="edit--station">
                                <span>
                                  <FiEdit2 color="skyblue" />
                                </span>
                                <span className="edit-station-text">Edit Station</span>
                              </div>
                            </li>
                          </ul>
                        </DropDown>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      {/* <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'general' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'general')}
                      data-content="tab-general"
                    >
                      General
                    </a> */}
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'chargers' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'chargers')}
                        data-content="tab-chargers"
                      >
                        Chargers
                      </a>
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'consumption' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'consumption')}
                        data-content="tab-consumption"
                      >
                        Consumption
                      </a>
                      {/* <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'reservations' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'reservations')}
                      data-content="tab-reservations"
                    >
                      Reservations
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'reviews' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'reviews')}
                      data-content="tab-reviews"
                    >
                      Reviews
                    </a> */}
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="station-details-tab">
                        {/* {currentTab === 'general' && <General />} */}
                        {currentTab === 'chargers' && <Chargers />}
                        {currentTab === 'consumption' && <Consumption />}
                        {/* {currentTab === 'reservations' && <Reservations />}
                      {currentTab === 'reviews' && <Reviews />} */}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
          <Footer />
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="station" onClose={handleCloseDeleteModel} onRemove={() => deleteChargingStation(chargingStationData.id)} />
          </Modal>
        )}
        {showInfoModal && (
          <Modal show={showInfoModal} size="xl" centered onHide={handleCloseInfoModal}>
            <React.Fragment>
              <div className="general-details__main">
                <div className="general-details--inner">
                  <div className="general-details__block">
                    <Row>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{`${commaSeperate(_.get(stationInfo, 'averageEnergyDeliverd', 0), 2)}`}</span>
                          <span className="information-title">{'Average Energy Delivered(kWh)'}</span>
                        </div>
                      </Col>
                      <Col xl={3} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{`${commaSeperate(_.get(stationInfo, 'totalEnergyDeliverd', 0), 2)}`}</span>
                          <span className="information-title">{'Total Energy Delivered(kWh)'}</span>
                        </div>
                      </Col>
                      <Col xl={2} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{_.get(stationInfo, 'noOfSession', 0)}</span>
                          <span className="information-title">{'No. of Sessions'}</span>
                        </div>
                      </Col>
                      <Col xl={2} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{convertHoursInHMS(_.get(stationInfo, 'averageChargingDuration', ''))}</span>
                          <span className="information-title">{'Average Charging Duration'}</span>
                        </div>
                      </Col>
                      <Col xl={2} md={6} className="general-details--wrapper">
                        <div className="general-stationdata-block">
                          <span className="information-data">{_.get(stationInfo, 'totalCharger', 'NA')}</span>
                          <span className="information-title">{'Total Chargers'}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="general-info__main">
                  <Row>
                    <Col xl={6} xs={12} className="general-info__main-wrapper">
                      <div className="general-energySold_chart_main">
                        <div className="general-energySold_chart_inner">
                          <div className="general-energySold_chart_block">
                            <div className="general-energySold_name">Energy Sold (kWh)</div>
                            <div className="general-energySold_subname">In Last 24Hrs</div>
                          </div>
                        </div>
                        <div className="general-energySold_chart-img">
                          {isLoader ? (
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                            </div>
                          ) : (
                            <div className="energySold_chart-img">
                              <Line data={energySold} options={options} height="110px" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{'Point of Contact'}</span>
                          </div>
                          <div className="operation-timing__content" key={`station-timing-${chargingStationData}`}>
                            <div className="operation--time">
                              <span className="operation-time-text">
                                {_.get(chargingStationData, 'contact_person.name', 'NA')} :{' '}
                                {`+${_.get(chargingStationData, 'contact_person.country_code', 'NA')}
                                     ${_.get(chargingStationData, 'contact_person.phone', 'NA')}`}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{'Operational Timings'}</span>
                          </div>
                          {_.map(chargingStationData.working_hours, (data, index) => {
                            return (
                              <div className="operation-timing__content" key={`station-timing-${index}`}>
                                <div className="operation--time">
                                  <span className="operation-time-text">{_.startCase(_.get(data, 'day', ''))}</span>
                                  <span className="operation-time-icon"></span>
                                  <span className="operation-time-text">
                                    {moment(_.get(data, 'from', ''), 'hh:mm A').format('LT')} -{' '}
                                    {moment(_.get(data, 'to', ''), 'hh:mm A').format('LT')}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="operational-timing--block">
                        <div className="operational-timing-inner">
                          <div className="operation-timing-text">
                            <span className="timing-text-inner">{'Address'}</span>
                          </div>
                          {_.map(chargingStationData.working_hours, (data, index) => {
                            return (
                              <div className="operation-timing__content" key={`station-timing-${index}`}>
                                <div className="operation--time">
                                  <span className="operation-time-text">
                                    {_.get(chargingStationData, 'address', '')}, {_.get(chargingStationData, 'area', '')},{' '}
                                    {_.get(chargingStationData, 'city', '')}, {_.get(chargingStationData, 'pincode', '')},{' '}
                                    {_.get(chargingStationData, 'state', '')}, {_.get(chargingStationData, 'country', '')}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} className="address__box">
                      <div className="address__box--inner">
                        <div className="station-map-img">
                          {isLoaded && (
                            <GoogleMap
                              onLoad={loadHandler}
                              center={_.get(chargingStationData, 'location') ? _.get(chargingStationData, 'location') : center}
                              zoom={15}
                              onCenterChanged={() => mapRef && setCenter(_.get(mapRef, 'getCenter()').toJSON())}
                              mapContainerStyle={{ width: '100%', height: '100%' }}
                            >
                              <Marker
                                key={_.get(chargingStationData, 'id', '')}
                                position={_.get(chargingStationData, 'location')}
                                icon={getMarkerIcon(chargingStationData.status, chargingStationData.access_type)}
                                onClick={() => handleActiveMarker(chargingStationData)}
                              >
                                {activeMarker === chargingStationData ? (
                                  <InfoWindow>
                                    <div className="marker-text">
                                      <b>
                                        <p className="station-marker-text" style={{ marginBottom: '0px', fontWeight: '600' }}>
                                          {_.get(chargingStationData, 'name', '')}
                                        </p>
                                      </b>
                                    </div>
                                  </InfoWindow>
                                ) : null}
                              </Marker>
                            </GoogleMap>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default StationDetails;
