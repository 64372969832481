import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { BsSearch } from 'react-icons/bs';
import { RiEqualizerFill } from 'react-icons/ri';
// import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaChargingStation, FaMapMarkerAlt } from 'react-icons/fa';
import { MdClose, MdRefresh } from 'react-icons/md';
// import ReactStars from 'react-rating-stars-component';
import { Offcanvas, Spinner } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_CHARGING_STATION, GET_STATION_WIDGETS } from 'actions/chargingStation';
import * as _ from 'lodash';
import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
// import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
// import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { Form, Formik } from 'formik';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import Footer from 'components/general/Footer';
import { RiChargingPile2Line } from 'react-icons/ri';
import { FiZapOff } from 'react-icons/fi';
import ReactDOMServer from 'react-dom/server';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { BsInfoCircle } from 'react-icons/bs';
import { FETCH_ZONES } from 'actions/zoneManagement';
import { TbPlugOff } from 'react-icons/tb';

const ChargerStationList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [allChargingStationData, setAllChargingStationData] = useState([]);
  // const [clickedLatLng, setClickedLatLng] = useState(null);
  const [activeMarker, setActiveMarker] = useState(false);
  // const [mapRef, setMapRef] = useState(null);
  // const [center, setCenter] = useState({
  //   lat: 24.4539,
  //   lng: 54.3773,
  // });
  const [mapCenter] = useState({ lat: 24.4539, lng: 54.3773 });
  const [filterData, setFilterData] = useState({});
  const [showLegend, setShowLegend] = useState(false);
  // const prevScrollY = useRef(0);
  // const [goingUp, setGoingUp] = useState(false);

  const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  const available = useSelector((state) => state.chargingStation.available);
  const unavailable = useSelector((state) => state.chargingStation.unavailable);
  const comingsoon = useSelector((state) => state.chargingStation.comingsoon);
  const inUse = useSelector((state) => state.chargingStation.inUse);
  const powerLoss = useSelector((state) => state.chargingStation.powerLoss);
  const underMaintenance = useSelector((state) => state.chargingStation.underMaintenance);
  const totalStations = useSelector((state) => state.chargingStation.totalCsCount);
  const disabled = useSelector((state) => state.chargingStation.disabledCount);

  const [totalCount, setTotalCount] = useState(totalStations);
  const [availableCount, setAvailableCount] = useState(available);
  const [unavailableCount, setUnavailableCount] = useState(unavailable);
  const [comingSoonCount, setComingSoonCount] = useState(comingsoon);
  const [inUseCount, setInUseCount] = useState(inUse);
  const [powerLossCount, setPowerLossCount] = useState(powerLoss);
  const [underMaintenanceCount, setUnderMaintenanceCount] = useState(underMaintenance);
  const [disabledCount, setDisabledCount] = useState(disabled);

  useEffect(() => {
    setTotalCount(totalStations);
    setAvailableCount(available);
    setUnavailableCount(unavailable);
    setComingSoonCount(comingsoon);
    setInUseCount(inUse);
    setPowerLossCount(powerLoss);
    setUnderMaintenanceCount(underMaintenance);
    setDisabledCount(disabled);
  }, [totalStations, available, unavailable, comingsoon, inUse, powerLoss, underMaintenance, disabled]);

  useEffect(() => {
    setAllChargingStationData(allChargingStations);
  }, [allChargingStations]);

  const getChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getWidgets = useCallback((data) => {
    dispatch({
      type: GET_STATION_WIDGETS,
      payload: data,
    });
  }, []);

  const updateStationList = (availability, isEnabled) => {
    // const isExternal = currentTab !== 'ourNetwork';
    const stationData = {
      status: availability,
      request_all: true,
      is_active: true,
      is_enabled: isEnabled,
      // is_external_station: isExternal,
    };

    dispatch({
      type: FETCH_CHARGING_STATION,
      payload: stationData,
    });
  };

  useEffect(() => {
    getChargingStations();
    getWidgets();
  }, []);

  const getDataUrlFromIcon = (color, size) => {
    const svgMarkup = ReactDOMServer.renderToStaticMarkup(<FaMapMarkerAlt color={color} size={size} />);

    const svgBlob = new Blob([svgMarkup], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);

    return url;
  };

  const colorCodePalette = [
    { icon: getDataUrlFromIcon('#75ed2f', 25), status: 'Available' },
    { icon: getDataUrlFromIcon('#ff8b00', 25), status: 'Unavailable' },
    { icon: getDataUrlFromIcon('#4a75f2', 25), status: 'Charging' },
    { icon: getDataUrlFromIcon('#8b91a1', 25), status: 'Coming Soon' },
    { icon: getDataUrlFromIcon('#be210b', 25), status: 'PowerLoss' },
  ];

  const getMarkerIcon = (status, accessType) => {
    if (accessType === 'Private') {
      return new L.Icon({
        iconUrl: getDataUrlFromIcon('#cba31b', 40),
        className: 'custom-icon',
      });
    }
    switch (status) {
      case 'Available':
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#75ed2f', 40),
          className: 'custom-icon',
        });
      case 'Unavailable':
      case 'Under Maintenance':
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#ff8b00', 40),
          className: 'custom-icon',
        });
      case 'Coming Soon':
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#8b91a1', 40),
          className: 'custom-icon',
        });
      case 'PowerLoss':
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#be210b', 40),
          className: 'custom-icon',
        });
      case 'In Use':
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#4a75f2', 40),
          className: 'custom-icon',
        });
      default:
        return new L.Icon({
          iconUrl: getDataUrlFromIcon('#8b91a1', 40),
          className: 'custom-icon',
        });
    }
  };

  useEffect(() => {
    setAllChargingStationData(
      _.filter(
        allChargingStations,
        (item) =>
          startsWith(item.name, searchText) ||
          startsWith(item.city, searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
      )
    );
  }, [searchText, allChargingStations]);

  const onCloseFilterPopup = () => setShowFilterPopup(false);

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  // });

  // const loadHandler = (map) => {
  //   // Store a reference to the google map instance in state
  //   setMapRef(map);
  // };

  // useEffect(() => {
  //   loadHandler();
  // }, [mapRef, center]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // const onScroll = (e) => {
  //   const scrollHeight = e.target.scrollHeight;
  //   const clientHeight = e.target.clientHeight;
  //   const currentScrollY = e.target.scrollTop;
  //   if (prevScrollY.current < currentScrollY && goingUp) {
  //     setGoingUp(false);
  //   }
  //   if (prevScrollY.current > currentScrollY && !goingUp) {
  //     setGoingUp(true);
  //   }
  //   prevScrollY.current = currentScrollY;

  //   if (e && scrollHeight - clientHeight === currentScrollY && page < totalPage) {
  //     const data = {
  //       page: page + 1,
  //     };
  //     getChargingStations(data);
  //   }
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader showButton title="Stations and Chargers" buttonText="Add charging station" onClickButton={() => history.push('/addStation')} />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="station-list-page__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="status-blocks">
                <div className="status-block" title="Total Number of Stations">
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<RiChargingPile2Line color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList(null, null)}>
                    <h1>{totalCount}</h1>
                    <p>{'Total Stations'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Stations Availabe">
                  <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                    {<RiChargingPile2Line color="#14AC7F" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('Available', true)}>
                    <h1>{availableCount}</h1>
                    <p>{'Available'}</p>
                  </div>
                </div>

                <div className="status-block" title="Number of Stations Unavailabe">
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<RiChargingPile2Line color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('Unavailable', true)}>
                    <h1>{unavailableCount}</h1>
                    <p>{'Unavailable'}</p>
                  </div>
                </div>

                <div className="status-block" title="InUse Stations">
                  <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                    {<RiChargingPile2Line color="#3C7CDD" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('In Use', true)}>
                    <h1>{inUseCount}</h1>
                    <p>{'In Use'}</p>
                  </div>
                </div>

                <div className="status-block" title="Coming Soon Stations">
                  <div className="breating-area" style={{ background: '#8c8ea421' }}>
                    {<RiChargingPile2Line color="#8C8EA4" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('Coming Soon', true)}>
                    <h1>{comingSoonCount}</h1>
                    <p>{'Coming Soon'}</p>
                  </div>
                </div>

                <div className="status-block" title="No Power Availability Stations">
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<FiZapOff color="#BE210B" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('PowerLoss', true)}>
                    <h1>{powerLossCount}</h1>
                    <p>{'PowerLoss'}</p>
                  </div>
                </div>

                <div className="status-block" title="Stations under maintanence">
                  <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                    {<RiChargingPile2Line color="#C9A11B" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList('Under Maintenance', true)}>
                    <h1>{underMaintenanceCount}</h1>
                    <p>{'Under Maintenance'}</p>
                  </div>
                </div>
                <div className="status-block" title="Disabled Stations">
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<TbPlugOff color="#BE210B" size={35} />}
                  </div>
                  <div className="status-info" onClick={() => updateStationList(null, false)}>
                    <h1>{disabledCount}</h1>
                    <p>{'Disabled'}</p>
                  </div>
                </div>
              </div>
              <div className="station-list-page__main">
                <div className="search--block">
                  <SearchBox
                    value={searchText}
                    preIcon={<BsSearch />}
                    postIcon={<RiEqualizerFill onClick={() => setShowFilterPopup(true)} />}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="map-station--outer-block">
                  <div className="station-list__block-main">
                    <div className="station__count-info">
                      <span>All stations</span>
                      <div className="total-station">
                        <span>{totalStations}</span>
                      </div>
                    </div>
                    <div className="station-list__block-inner scrollable">
                      {searchText && _.isEmpty(allChargingStationData) ? (
                        <div className="empty-data-block">No charging stations</div>
                      ) : (
                        _.map(allChargingStationData, (station, index) => (
                          <StationInfoBlock
                            key={`charging-station-${index}`}
                            stationId={_.get(station, 'id', '')}
                            name={_.get(station, 'name', '')}
                            city={_.get(station, 'city', '')}
                            // rating={_.get(station, 'avg_rating', 0)}
                            // ratingByUsersCount={_.get(station, 'rating_count', 0)}
                            totalCharger={_.get(station, 'total_chargers', 0)}
                            is_active={_.get(station, 'is_active')}
                          />
                        ))
                      )}
                    </div>
                  </div>
                  <div className="map__block-main">
                    <MapContainer center={[mapCenter.lat, mapCenter.lng]} zoom={6} style={{ height: '100%', width: '100%', borderRadius: '1rem' }}>
                      <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@4x.png" />
                      {_.map(allChargingStationData, (item, index) => {
                        const lat = _.get(item, 'location.lat');
                        const lng = _.get(item, 'location.lng');
                        if (!lat || !lng) return null; // Skip this marker if lat or lng is missing

                        return (
                          <Marker
                            key={index}
                            position={[lat, lng]}
                            icon={getMarkerIcon(item.status, item.access_type)}
                            eventHandlers={{
                              mouseover: () => handleActiveMarker(item),
                            }}
                          >
                            {activeMarker === item && (
                              <Popup>
                                <div className="marker-text">
                                  <b>
                                    <p
                                      style={{ marginBottom: '0px', fontWeight: '600' }}
                                      onClick={() => history.push(`/stations/${_.get(item, 'id')}`)}
                                    >
                                      {_.get(item, 'name', '')}
                                    </p>
                                  </b>
                                  <div style={{ fontSize: 'big', background: '#F7FDFE', padding: '10px', marginTop: '5px', borderRadius: '10px' }}>
                                    {_.get(item, 'chargers', []).map((charger, chargerIndex) => (
                                      <div key={chargerIndex}>
                                        <div>
                                          <p style={{ color: 'Black', margin: '0', padding: '0px 0px', marginBottom: '15px' }}>
                                            {' '}
                                            {charger.charger_id}
                                          </p>
                                          <div style={{ display: 'flex', alignItems: 'center', fontSize: 'small' }}>
                                            {charger.plugs.map((plug, plugIndex) => (
                                              <div
                                                key={plugIndex}
                                                style={{ display: 'flex', alignItems: 'center', marginRight: '8px', marginTop: '-10px' }}
                                              >
                                                {/* <SettingsInputSvideoIcon style={{ fontSize: '16px', marginRight: '4px' }} /> */}
                                                <b>
                                                  <span>{plug.name}</span>
                                                  <span
                                                    style={{
                                                      color: plug.status === 'Available' ? 'Teal' : plug.status === 'Charging' ? 'royalblue' : 'red',
                                                      marginLeft: '4px',
                                                    }}
                                                    onClick={() => {
                                                      history.push(`/stations/${_.get(item, 'id')}`);
                                                    }}
                                                  >
                                                    ({plug.status})
                                                  </span>
                                                </b>
                                              </div>
                                            ))}
                                          </div>
                                          <p style={{ margin: '5px 0px', color: 'darkgray', fontSize: 'smaller' }}>
                                            {'Last Updated'} {new Date(charger.updatedAt).toLocaleString()}
                                          </p>
                                          <p
                                            style={{
                                              cursor: 'pointer',
                                              color: 'darkgray',
                                              textDecoration: 'underline',
                                              margin: '0',
                                              padding: '0px 0px',
                                            }}
                                            onClick={() => {
                                              history.push(`/charger/${charger.id}`);
                                            }}
                                          >
                                            {'View More'}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div style={{ marginBottom: '5px', paddingLeft: '10px', marginTop: '5px' }}>
                                  <p
                                    style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline', margin: '0', padding: '5px 0px' }}
                                    onClick={() => {
                                      const latitude = _.get(item, 'location.lat');
                                      const longitude = _.get(item, 'location.lng');
                                      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
                                      window.open(url, '_blank');
                                    }}
                                  >
                                    {'Open In Google Map'}
                                  </p>
                                </div>
                              </Popup>
                            )}
                          </Marker>
                        );
                      })}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '30px',
                          left: '20px',
                          zIndex: 1000,
                          display: 'flex',
                          flexDirection: 'column',
                          background: 'rgba(21, 33, 43, 0.9)',
                          padding: '10px',
                          fontSize: 'small',
                          borderRadius: '20px',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                          color: 'white',
                          fontFamily: 'Arial, sans-serif',
                        }}
                        onMouseEnter={() => setShowLegend(true)}
                        onMouseLeave={() => setShowLegend(false)}
                      >
                        {showLegend ? (
                          <>
                            <h5
                              style={{
                                marginBottom: '15px',
                                fontSize: '1.0rem',
                                fontWeight: 'bold',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
                                paddingBottom: '10px',
                              }}
                            >
                              {'CHARGER STATUS'}
                            </h5>
                            {colorCodePalette.map((item) => (
                              <div key={item.status} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ marginRight: '10px' }}>
                                  <img src={item.icon} alt={item.status} style={{ width: '20px', height: '25px', marginBottom: '2px' }} />
                                </div>
                                <span>{item.status}</span>
                              </div>
                            ))}
                          </>
                        ) : (
                          // Display the (i) icon when legend is hidden
                          <BsInfoCircle style={{ fontSize: '1.5rem', color: 'white' }} />
                        )}
                      </div>
                    </MapContainer>
                  </div>
                </div>
              </div>
            </>
          )}
          <Footer />
        </div>
      </LayoutContainer>
      {showFilterPopup && (
        <StationFilterBlock
          show={showFilterPopup}
          onClose={onCloseFilterPopup}
          filterData={filterData}
          handleFilterData={(data) => setFilterData(data)}
        />
      )}
    </React.Fragment>
  );
};
ChargerStationList.propTypes = {};
export default ChargerStationList;

export const StationInfoBlock = (props) => {
  const { stationId, name, city, totalCharger, is_active } = props;
  const history = useHistory();

  return (
    <div className="station-list-item__main" onClick={() => history.push(`/stations/${stationId}`)}>
      <div className="station-item__inner">
        <div className="station--icon-block">
          <div className="station--icon">
            <FaChargingStation />
          </div>
        </div>
        <div className="station--info-block">
          <div className="station--info_inner">
            <span className="station--name">{name}</span>
            <div className="station--rating-block">
              <div className="rating-star__block">
                <FaMapMarkerAlt color="#3C7CDD" /> <span className="rating__count">{city}</span>
              </div>
            </div>
            <div className="station--rating-block">
              <div className="rating-star__block">
                <FaChargingStation color="#be210b" /> <span className="rating__count">{totalCharger} Chargers</span>
              </div>
            </div>
          </div>
          {is_active === false && (
            <div className="station-active__main">
              <span className="station-active-text">In Draft</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
StationInfoBlock.propTypes = {
  stationId: PropTypes.string,
  name: PropTypes.string,
  city: PropTypes.string,
  rating: PropTypes.number,
  ratingByUsersCount: PropTypes.number,
  totalCharger: PropTypes.number,
  is_active: PropTypes.bool,
};

export const StationFilterBlock = (props) => {
  const { show, onClose, filterData, handleFilterData } = props;
  const dispatch = useDispatch();
  // const [isAvailable, setIsAvailable] = useState(true);
  // const allCountries = useSelector((state) => state.country.countries);
  // const allState = useSelector((state) => state.state.states);
  // const allCity = useSelector((state) => state.city.city);
  // const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  // const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  // const countryPage = useSelector((state) => state.country.page);
  // const statePage = useSelector((state) => state.state.page);
  // const cityPage = useSelector((state) => state.city.page);
  // const countryTotalPage = useSelector((state) => state.country.totalPages);
  // const stateTotalPage = useSelector((state) => state.state.totalPages);
  // const cityTotalPage = useSelector((state) => state.city.totalPages);
  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);

  const initialValues = !_.isEmpty(filterData)
    ? { ...filterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: true, charger_availibity: '', zones: '' };

  const getAllChargingStations = useCallback((data = {}) => {
    const stationData = {
      ...data,
      limit: 99,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  // const getAllCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_COUNTRY, payload: data });
  // }, []);

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  // const getStateByCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_STATE, payload: data });
  // }, []);

  // const getCityByState = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_CITY, payload: data });
  // }, []);

  useEffect(() => {
    // getAllCountry();
    getAllZones({ limit: 999 });
    dispatch({ type: FETCH_OEM_VENDOR });
    dispatch({ type: FETCH_CHARGE_SPEED });
  }, []);

  return (
    <Offcanvas show={show} placement="end" onHide={onClose} className="station-list-filter-canvas__container">
      <div className="filter-canvas__inner">
        <div className="canvas__header-block">
          <div className="header-name">Filters</div>
          <div className="header-close-icon">
            <MdClose size={30} color="#be210b" onClick={onClose} />
          </div>
        </div>
        <div className="canvas__body-block">
          <div className="canvas__body-block--inner">
            <Formik
              enableReinitialize={!_.isEmpty(filterData)}
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                !values.country && delete values['country'];
                !values.state && delete values['state'];
                !values.city && delete values['city'];
                !values.oem && delete values['oem'];
                !values.charger_availibity && delete values['charger_availibity'];
                !values.charger_type && delete values['charger_type'];
                !values.zones && delete values['zones'];
                getAllChargingStations(values);
                handleFilterData(values);
                onClose();
                setSubmitting(false);
              }}
            >
              {({ values, handleSubmit, setFieldValue, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    {/* <Select
                      label="Country"
                      options={_.map(allCountries, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder="Select Country"
                      name="country"
                      value={values.country}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllCountry}
                      page={countryPage}
                      totalPage={countryTotalPage}
                      onChange={(val) => {
                        getStateByCountry({ country_name: val });
                        setFieldValue(`country`, val);
                        setFieldValue(`state`, '');
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label="State"
                      options={_.map(allState, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      className="filter-select-box"
                      placeholder="Select State"
                      name="state"
                      value={values.state}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                      page={statePage}
                      totalPage={stateTotalPage}
                      onChange={(val) => {
                        getCityByState({ state_name: val });
                        const currentState = _.find(allState, { name: val });
                        setFieldValue(`state`, currentState.name);
                        setFieldValue(`city`, '');
                      }}
                    />
                    <Select
                      label="City"
                      options={
                        values.state &&
                        _.map(allCity, (item) => {
                          return { label: item.name, value: item.name };
                        })
                      }
                      placeholder="Select City"
                      name="city"
                      value={values.city}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                      page={cityPage}
                      totalPage={cityTotalPage}
                      onChange={(val) => {
                        const currentCity = _.find(allCity, { name: val });
                        setFieldValue('city', currentCity.name);
                      }}
                    />
                    <Select
                      label="OEM Name"
                      options={_.map(allOemVendor, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      placeholder="Select OEM Name"
                      name="oem"
                      value={_.get(values, 'oem', '')}
                      onChange={(val) => setFieldValue(`oem`, val)}
                    /> */}
                    {/* <div className="canvas-selection__block">
                      <Select
                        label="Type Of Charger"
                        options={_.map(allChargeSpeed, (item) => {
                          return { label: item.name, value: item.id };
                        })}
                        placeholder="Select Charge Speed"
                        name="charger_type"
                        value={values.charger_type}
                        onChange={(val) => setFieldValue('charger_type', val)}
                      />
                    </div>
                    <div className="canvas-selection__block">
                      <label htmlFor="available_charger" className="form-select-label">
                        Availability
                      </label>
                      <div id="available_charger" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? 'active' : ''}`} onClick={() => setFieldValue('is_active', true)}>
                              In use
                            </div>
                          </Col>
                          <Col>
                            <div className={`selection--btn ${values.is_active ? '' : 'active'}`} onClick={() => setFieldValue('is_active', false)}>
                              Not In use
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="canvas-selection__block">
                      <label htmlFor="charger_status" className="form-select-label">
                        Status
                      </label>
                      <div id="charger_status" className="selection-block__inner">
                        <Row>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? 'active' : ''}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Available ');
                                setIsAvailable(true);
                              }}
                            >
                              Active
                            </div>
                          </Col>
                          <Col>
                            <div
                              className={`selection--btn ${isAvailable ? '' : 'active'}`}
                              onClick={() => {
                                setFieldValue('charger_availibity', 'Unavailable');
                                setIsAvailable(false);
                              }}
                            >
                              Inactive
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    <Select
                      label="Zones"
                      isMulti
                      options={_.map(allZones, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder="Select Zones"
                      name="zones"
                      value={values.zones}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllZones}
                      page={zonePage}
                      totalPage={zoneTotalPage}
                      onChange={(val) => {
                        setFieldValue(`zones`, val);
                      }}
                    />
                  </div>
                  <div className="canvas-btn__block">
                    <Button type="submit" className="apply--btn w-100">
                      Apply Filters
                    </Button>
                    <div
                      className="reset--block"
                      onClick={() => {
                        handleFilterData({});
                        getAllChargingStations();
                        resetForm();
                        onClose();
                      }}
                    >
                      <MdRefresh size={24} className="reset--icon" />
                      Reset
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};
StationFilterBlock.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  filterData: PropTypes.object,
  handleFilterData: PropTypes.func,
};
