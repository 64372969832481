export const STATION_UNIT_CONSUMPTION = 'STATION_UNIT_CONSUMPTION';
export const STATION_UNIT_CONSUMPTION_SUCCESS = 'STATION_UNIT_CONSUMPTION_SUCCESS';
export const STATION_UNIT_CONSUMPTION_FAIL = 'STATION_UNIT_CONSUMPTION_FAIL';

export const B2B_STATION_CHARGING_HISTORY = 'B2B_STATION_CHARGING_HISTORY';
export const B2B_STATION_CHARGING_HISTORY_SUCCESS = 'B2B_STATION_CHARGING_HISTORY_SUCCESS';
export const B2B_STATION_CHARGING_HISTORY_FAIL = 'B2B_STATION_CHARGING_HISTORY_FAIL';

export const B2C_STATION_CHARGING_HISTORY = 'B2C_STATION_CHARGING_HISTORY';
export const B2C_STATION_CHARGING_HISTORY_SUCCESS = 'B2C_STATION_CHARGING_HISTORY_SUCCESS';
export const B2C_STATION_CHARGING_HISTORY_FAIL = 'B2C_STATION_CHARGING_HISTORY_FAIL';

export const JSON_REQUEST_RESPONSE_REPORT = 'JSON_REQUEST_RESPONSE_REPORT';
export const JSON_REQUEST_RESPONSE_REPORT_SUCCESS = 'JSON_REQUEST_RESPONSE_REPORT_SUCCESS';
export const JSON_REQUEST_RESPONSE_REPORT_FAIL = 'JSON_REQUEST_RESPONSE_REPORT_FAIL';

export const WALLET_TRANSACTION_REPORT = 'WALLET_TRANSACTION_REPORT';
export const WALLET_TRANSACTION_REPORT_SUCCESS = 'WALLET_TRANSACTION_REPORT_SUCCESS';
export const WALLET_TRANSACTION_REPORT_FAIL = 'WALLET_TRANSACTION_REPORT_FAIL';

export const MONEY_TRANSACTION_PG_REPORT = 'MONEY_TRANSACTION_PG_REPORT';
export const MONEY_TRANSACTION_PG_REPORT_SUCCESS = 'MONEY_TRANSACTION_PG_REPORT_SUCCESS';
export const MONEY_TRANSACTION_PG_REPORT_FAIL = 'MONEY_TRANSACTION_PG_REPORT_FAIL';

export const APP_USER_DETAILS_LIST = 'APP_USER_DETAILS_LIST';
export const APP_USER_DETAILS_LIST_SUCCESS = 'APP_USER_DETAILS_LIST_SUCCESS';
export const APP_USER_DETAILS_LIST_FAIL = 'APP_USER_DETAILS_LIST_FAIL';

export const MOST_VEHICLE_CHARGED_LIST = 'MOST_VEHICLE_CHARGED_LIST';
export const MOST_VEHICLE_CHARGED_LIST_SUCCESS = 'MOST_VEHICLE_CHARGED_LIST_SUCCESS';
export const MOST_VEHICLE_CHARGED_LIST_FAIL = 'MOST_VEHICLE_CHARGED_LIST_FAIL';

export const BOOKING_HISTORY = 'BOOKING_HISTORY';
export const BOOKING_HISTORY_SUCCESS = 'BOOKING_HISTORY_SUCCESS';
export const BOOKING_HISTORY_FAIL = 'BOOKING_HISTORY_FAIL';

export const USER_CHARGING_HISTORY = 'USER_CHARGING_HISTORY';
export const USER_CHARGING_HISTORY_SUCCESS = 'USER_CHARGING_HISTORY_SUCCESS';
export const USER_CHARGING_HISTORY_FAIL = 'USER_CHARGING_HISTORY_FAIL';

export const STATION_CHARGING_SUMMARY = 'STATION_CHARGING_SUMMARY';
export const STATION_CHARGING_SUMMARY_SUCCESS = 'STATION_CHARGING_SUMMARY_SUCCESS';
export const STATION_CHARGING_SUMMARY_FAIL = 'STATION_CHARGING_SUMMARY_FAIL';

export const BOOKING_SUMMARY_REPORT = 'BOOKING_SUMMARY_REPORT';
export const BOOKING_SUMMARY_REPORT_SUCCESS = 'BOOKING_SUMMARY_REPORT_SUCCESS';
export const BOOKING_SUMMARY_REPORT_FAIL = 'BOOKING_SUMMARY_REPORT_FAIL';

export const DOWNLOAD_STATION_CHARGING_SUMMARY = 'DOWNLOAD_STATION_CHARGING_SUMMARY';
export const DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS = 'DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS';
export const DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL = 'DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL';

export const DOWNLOAD_BOOKING_HISTORY = 'DOWNLOAD_BOOKING_HISTORY';
export const DOWNLOAD_BOOKING_HISTORY_SUCCESS = 'DOWNLOAD_BOOKING_HISTORY_SUCCESS';
export const DOWNLOAD_BOOKING_HISTORY_FAIL = 'DOWNLOAD_BOOKING_HISTORY_FAIL';

export const DOWNLOAD_USER_CHARGING_HISTORY = 'DOWNLOAD_USER_CHARGING_HISTORY';
export const DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_USER_CHARGING_HISTORY_FAIL = 'DOWNLOAD_USER_CHARGING_HISTORY_FAIL';

export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY';
export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS';
export const DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL = 'DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL';

export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT';
export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS';
export const DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL = 'DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL';

export const DOWNLOAD_WALLET_TRANSACTION_REPORT = 'DOWNLOAD_WALLET_TRANSACTION_REPORT';
export const DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS = 'DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS';
export const DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL = 'DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL';

export const DOWNLOAD_STATION_UNIT_CONSUMPTION = 'DOWNLOAD_STATION_UNIT_CONSUMPTION';
export const DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS = 'DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS';
export const DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL = 'DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL';

export const ACCESS_REPORT = 'ACCESS_REPORT';
export const ACCESS_REPORT_SUCCESS = 'ACCESS_REPORT_SUCCESS';
export const ACCESS_REPORT_FAIL = 'ACCESS_REPORT_FAIL';

export const DOWNLOAD_ACCESS_REPORT = 'DOWNLOAD_ACCESS_REPORT';
export const DOWNLOAD_ACCESS_REPORT_SUCCESS = 'DOWNLOAD_ACCESS_REPORT_SUCCESS';
export const DOWNLOAD_ACCESS_REPORT_FAIL = 'DOWNLOAD_ACCESS_REPORT_FAIL';

export const CAPACITY_UTILISATION = 'CAPACITY_UTILISATION';
export const CAPACITY_UTILISATION_SUCCESS = 'CAPACITY_UTILISATION_SUCCESS';
export const CAPACITY_UTILISATION_FAIL = 'CAPACITY_UTILISATION_FAIL';

export const DOWNLOAD_CAPACITY_DATA = 'DOWNLOAD_CAPACITY_DATA';
export const DOWNLOAD_CAPACITY_DATA_SUCCESS = 'DOWNLOAD_CAPACITY_DATA_SUCCESS';
export const DOWNLOAD_CAPACITY_DATA_ERROR = 'DOWNLOAD_CAPACITY_DATA_ERROR';

export const STATION_DOWNTIME_REPORT = 'STATION_DOWNTIME_REPORT';
export const STATION_DOWNTIME_REPORT_SUCCESS = 'STATION_DOWNTIME_REPORT_SUCCESS';
export const STATION_DOWNTIME_REPORT_FAIL = 'STATION_DOWNTIME_REPORT_FAIL';

export const DOWNLOAD_STATION_DOWNTIME_REPORT = 'DOWNLOAD_STATION_DOWNTIME_REPORT';
export const DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS = 'DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS';
export const DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL = 'DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL';

export const CHARGE_TRANSACTION_REPORT = 'CHARGE_TRANSACTION_REPORT';
export const CHARGE_TRANSACTION_REPORT_SUCCESS = 'CHARGE_TRANSACTION_REPORT_SUCCESS';
export const CHARGE_TRANSACTION_REPORT_FAIL = 'CHARGE_TRANSACTION_REPORT_FAIL';

export const DOWNLOAD_CHARGE_TRANSACTION_REPORT = 'DOWNLOAD_CHARGE_TRANSACTION_REPORT';
export const DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS = 'DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS';
export const DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL = 'DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL';

export const FAILED_TRANSACTION_REPORT = 'FAILED_TRANSACTION_REPORT';
export const FAILED_TRANSACTION_REPORT_SUCCESS = 'FAILED_TRANSACTION_REPORT_SUCCESS';
export const FAILED_TRANSACTION_REPORT_FAIL = 'FAILED_TRANSACTION_REPORT_FAIL';

export const DOWNLOAD_FAILED_TRANSACTION_REPORT = 'DOWNLOAD_FAILED_TRANSACTION_REPORT';
export const DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS = 'DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS';
export const DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL = 'DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL';