import {
  STATION_UNIT_CONSUMPTION,
  STATION_UNIT_CONSUMPTION_SUCCESS,
  STATION_UNIT_CONSUMPTION_FAIL,
  B2B_STATION_CHARGING_HISTORY,
  B2B_STATION_CHARGING_HISTORY_SUCCESS,
  B2B_STATION_CHARGING_HISTORY_FAIL,
  B2C_STATION_CHARGING_HISTORY_SUCCESS,
  B2C_STATION_CHARGING_HISTORY_FAIL,
  B2C_STATION_CHARGING_HISTORY,
  JSON_REQUEST_RESPONSE_REPORT_SUCCESS,
  JSON_REQUEST_RESPONSE_REPORT_FAIL,
  JSON_REQUEST_RESPONSE_REPORT,
  WALLET_TRANSACTION_REPORT_SUCCESS,
  WALLET_TRANSACTION_REPORT_FAIL,
  WALLET_TRANSACTION_REPORT,
  MOST_VEHICLE_CHARGED_LIST,
  MOST_VEHICLE_CHARGED_LIST_SUCCESS,
  MOST_VEHICLE_CHARGED_LIST_FAIL,
  APP_USER_DETAILS_LIST_SUCCESS,
  APP_USER_DETAILS_LIST_FAIL,
  APP_USER_DETAILS_LIST,
  STATION_CHARGING_SUMMARY_SUCCESS,
  STATION_CHARGING_SUMMARY_FAIL,
  STATION_CHARGING_SUMMARY,
  BOOKING_HISTORY_SUCCESS,
  BOOKING_HISTORY_FAIL,
  BOOKING_HISTORY,
  USER_CHARGING_HISTORY_SUCCESS,
  USER_CHARGING_HISTORY_FAIL,
  USER_CHARGING_HISTORY,
  BOOKING_SUMMARY_REPORT_SUCCESS,
  BOOKING_SUMMARY_REPORT_FAIL,
  BOOKING_SUMMARY_REPORT,
  MONEY_TRANSACTION_PG_REPORT_SUCCESS,
  MONEY_TRANSACTION_PG_REPORT_FAIL,
  MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_BOOKING_HISTORY_SUCCESS,
  DOWNLOAD_BOOKING_HISTORY_FAIL,
  DOWNLOAD_BOOKING_HISTORY,
  DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_USER_CHARGING_HISTORY_FAIL,
  DOWNLOAD_USER_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS,
  DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_MONEY_TRANSACTION_PG_REPORT,
  DOWNLOAD_WALLET_TRANSACTION_REPORT,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL,
  ACCESS_REPORT,
  ACCESS_REPORT_SUCCESS,
  ACCESS_REPORT_FAIL,
  DOWNLOAD_ACCESS_REPORT,
  DOWNLOAD_ACCESS_REPORT_SUCCESS,
  DOWNLOAD_ACCESS_REPORT_FAIL,
  CAPACITY_UTILISATION,
  CAPACITY_UTILISATION_SUCCESS,
  CAPACITY_UTILISATION_FAIL,
  DOWNLOAD_CAPACITY_DATA,
  DOWNLOAD_CAPACITY_DATA_SUCCESS,
  DOWNLOAD_CAPACITY_DATA_ERROR,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS,
  DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL,
  STATION_DOWNTIME_REPORT,
  STATION_DOWNTIME_REPORT_SUCCESS,
  STATION_DOWNTIME_REPORT_FAIL,
  CHARGE_TRANSACTION_REPORT,
  CHARGE_TRANSACTION_REPORT_SUCCESS,
  CHARGE_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL,
  FAILED_TRANSACTION_REPORT,
  FAILED_TRANSACTION_REPORT_SUCCESS,
  FAILED_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_FAILED_TRANSACTION_REPORT,
  DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL,
} from 'actions/dataReport';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* stationUnitConsumption(action) {
  try {
    const response = yield call(API.stationUnitConsumption, action.payload);
    yield put({ type: STATION_UNIT_CONSUMPTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_UNIT_CONSUMPTION_FAIL, payload: { error: e } });
  }
}

function* downloadStationUnitConsumption(action) {
  try {
    const response = yield call(API.downloadStationUnitConsumption, action.payload);
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* b2bStationChargingHistory(action) {
  try {
    const response = yield call(API.b2bStationChargingHistory, action.payload);
    yield put({ type: B2B_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: B2B_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* b2cStationChargingHistory(action) {
  try {
    const response = yield call(API.b2cStationChargingHistory, action.payload);
    yield put({ type: B2C_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: B2C_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadB2CStationChargingHistory(action) {
  try {
    const response = yield call(API.downloadB2CStationChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* jsonRequestResponseReport(action) {
  try {
    const response = yield call(API.jsonRequestResponseReport, action.payload);
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: JSON_REQUEST_RESPONSE_REPORT_FAIL, payload: { error: e } });
  }
}

function* walletTransactionReport(action) {
  try {
    const response = yield call(API.walletTransactionReport, action.payload);
    yield put({ type: WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadWalletTransactionReport(action) {
  try {
    const response = yield call(API.downloadWalletTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_WALLET_TRANSACTION_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* appUserDetailsList(action) {
  try {
    const response = yield call(API.appUserDetailsList, action.payload);
    yield put({ type: APP_USER_DETAILS_LIST_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: APP_USER_DETAILS_LIST_FAIL, payload: { error: e } });
  }
}

function* bookingHistory(action) {
  try {
    const response = yield call(API.bookingHistory, action.payload);
    yield put({ type: BOOKING_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: BOOKING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadBookingHistory(action) {
  try {
    const response = yield call(API.downloadBookingHistory, action.payload);
    yield put({ type: DOWNLOAD_BOOKING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_BOOKING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* mostVehicleChargedList(action) {
  try {
    const response = yield call(API.mostVehicleChargedList, action.payload);
    yield put({ type: MOST_VEHICLE_CHARGED_LIST_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: MOST_VEHICLE_CHARGED_LIST_FAIL, payload: { error: e } });
  }
}

function* userChargingHistory(action) {
  try {
    const response = yield call(API.userChargingHistory, action.payload);
    yield put({ type: USER_CHARGING_HISTORY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: USER_CHARGING_HISTORY_FAIL, payload: { error: e } });
  }
}

function* downloadUserChargingHistory(action) {
  try {
    const response = yield call(API.downloadUserChargingHistory, action.payload);
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_USER_CHARGING_HISTORY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* stationChargingSummary(action) {
  try {
    const response = yield call(API.stationChargingSummary, action.payload);
    yield put({ type: STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
  }
}

function* downloadStationChargingSummary(action) {
  try {
    const response = yield call(API.downloadStationChargingSummary, action.payload);
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* bookingSummaryReport(action) {
  try {
    const response = yield call(API.bookingSummaryReport, action.payload);
    yield put({ type: BOOKING_SUMMARY_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: BOOKING_SUMMARY_REPORT_FAIL, payload: { error: e } });
  }
}

function* moneyTransactionPgReport(action) {
  try {
    const response = yield call(API.moneyTransactionPgReport, action.payload);
    yield put({ type: MONEY_TRANSACTION_PG_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: MONEY_TRANSACTION_PG_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadMoneyTransactionPGReport(action) {
  try {
    const response = yield call(API.downloadMoneyTransactionPGReport, action.payload);
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_MONEY_TRANSACTION_PG_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* accessReport(action) {
  try {
    const response = yield call(API.accessReport, action.payload);
    yield put({ type: ACCESS_REPORT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: ACCESS_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadAccessReport(action) {
  try {
    const response = yield call(API.downloadAccessReport, action.payload);
    yield put({ type: DOWNLOAD_ACCESS_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_ACCESS_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getCapacityUtilisation(action) {
  try {
    const response = yield call(API.getCapacityUtilisation, action.payload);
    yield put({ type: CAPACITY_UTILISATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CAPACITY_UTILISATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadCapacityUtilisation(action) {
  try {
    const response = yield call(API.downloadCapacityUtilisation, action.payload);
    yield put({ type: DOWNLOAD_CAPACITY_DATA_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CAPACITY_DATA_ERROR, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* stationDownTimeReport(action) {
  try {
    const response = yield call(API.stationDownTimeReport, action.payload);
    yield put({ type: STATION_DOWNTIME_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STATION_DOWNTIME_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadStationDownTimeReport(action) {
  try {
    const response = yield call(API.downloadStationDownTimeReport, action.payload);
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargeTransactionReport(action) {
  try {
    const response = yield call(API.chargeTransactionReport, action.payload);
    yield put({ type: CHARGE_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGE_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadChargeTransactionReport(action) {
  try {
    const response = yield call(API.downloadChargeTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* failedTransactionReport(action) {
  try {
    const response = yield call(API.failedTransactionReport, action.payload);
    yield put({ type: FAILED_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FAILED_TRANSACTION_REPORT_FAIL, payload: { error: e } });
  }
}

function* downloadFailedTransactionReport(action) {
  try {
    const response = yield call(API.downloadFailedTransactionReport, action.payload);
    yield put({ type: DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* dataReport() {
  yield takeLatest(STATION_UNIT_CONSUMPTION, stationUnitConsumption);
  yield takeLatest(B2B_STATION_CHARGING_HISTORY, b2bStationChargingHistory);
  yield takeLatest(B2C_STATION_CHARGING_HISTORY, b2cStationChargingHistory);
  yield takeLatest(JSON_REQUEST_RESPONSE_REPORT, jsonRequestResponseReport);
  yield takeLatest(WALLET_TRANSACTION_REPORT, walletTransactionReport);
  yield takeLatest(BOOKING_HISTORY, bookingHistory);
  yield takeLatest(APP_USER_DETAILS_LIST, appUserDetailsList);
  yield takeLatest(MOST_VEHICLE_CHARGED_LIST, mostVehicleChargedList);
  yield takeLatest(USER_CHARGING_HISTORY, userChargingHistory);
  yield takeLatest(STATION_CHARGING_SUMMARY, stationChargingSummary);
  yield takeLatest(BOOKING_SUMMARY_REPORT, bookingSummaryReport);
  yield takeLatest(MONEY_TRANSACTION_PG_REPORT, moneyTransactionPgReport);
  yield takeLatest(DOWNLOAD_STATION_CHARGING_SUMMARY, downloadStationChargingSummary);
  yield takeLatest(DOWNLOAD_BOOKING_HISTORY, downloadBookingHistory);
  yield takeLatest(DOWNLOAD_USER_CHARGING_HISTORY, downloadUserChargingHistory);
  yield takeLatest(DOWNLOAD_B2C_STATION_CHARGING_HISTORY, downloadB2CStationChargingHistory);
  yield takeLatest(DOWNLOAD_MONEY_TRANSACTION_PG_REPORT, downloadMoneyTransactionPGReport);
  yield takeLatest(DOWNLOAD_WALLET_TRANSACTION_REPORT, downloadWalletTransactionReport);
  yield takeLatest(DOWNLOAD_STATION_UNIT_CONSUMPTION, downloadStationUnitConsumption);
  yield takeLatest(DOWNLOAD_ACCESS_REPORT, downloadAccessReport);
  yield takeLatest(ACCESS_REPORT, accessReport);
  yield takeLatest(CAPACITY_UTILISATION, getCapacityUtilisation);
  yield takeLatest(DOWNLOAD_CAPACITY_DATA, downloadCapacityUtilisation);
  yield takeLatest(STATION_DOWNTIME_REPORT, stationDownTimeReport);
  yield takeLatest(DOWNLOAD_STATION_DOWNTIME_REPORT, downloadStationDownTimeReport);
  yield takeLatest(CHARGE_TRANSACTION_REPORT, chargeTransactionReport);
  yield takeLatest(DOWNLOAD_CHARGE_TRANSACTION_REPORT, downloadChargeTransactionReport);
  yield takeLatest(FAILED_TRANSACTION_REPORT, failedTransactionReport);
  yield takeLatest(DOWNLOAD_FAILED_TRANSACTION_REPORT, downloadFailedTransactionReport);
}
export default dataReport;
